import React from "react";
import { Helmet } from "react-helmet";
import '../styles/styles.css';

import index1 from '../images/storkoeb.png'

//Components
import CollapsibleWrapper from "../components/CollapsibleWrapper";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

//Constants
import { StorkoebTexts } from "../components/texts/StorkoebTexts";

const Storkoeb = () => {

    return (
        <>
            <Helmet>
                <title>Storkøb | Klippingeslagteren</title>
            </Helmet>
            <Navbar />
            <div className='mt-4 mb-8 mt-28 pb-4 flex flex-col justify-evenly md:mt-44 md:flex-row'>
                <div className='flex flex-col w-full -mb-6 mx-auto md:w-5/12'>
                    <CollapsibleWrapper content={ StorkoebTexts } />
                </div>

                <div className="w-11/12 self-start bg-gray-light text-left rounded-2xl mx-auto my-4 py-10 px-10 md:my-0 md:w-1/2">
                    Forudbestil gerne kødpakker og storkøb i god tid på tlf. eller mail.
                    Vi tager også imod opkald på vores lukkedage. <br />
                    De bestilte varer kan afhentes i vores åbningstider eller torsdag
                    efter aftale i butikken på Tværvej 5, 4672 Klippinge. <br />
                    Alle kødpakker og storkøb er pakket og klar til fryseren.
                    <br /><br />
                    Der tages forbehold for udsolgte varer og trykfejl.<br />
                    Alle priser er afhentningspriser, der bliver reguleret efter
                    noteringen. Alle priser er inkl. moms. <br />
                    <br />
                    <img
                        src={index1}
                        className="mx-auto w-full object-cover rounded-2xl md:h-72"
                        alt='storkøb'
                        width="580"
                        height="240" />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Storkoeb